import React, { useState } from "react";
import ChatBot from "react-chatbotify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import { QrReader } from "react-qr-reader";

// QRCodeScanner Component
const QRCodeScanner = ({ onScanComplete }) => {
    const [cameraActive, setCameraActive] = useState(false);

    const handleScan = (data) => {
        if (data) {
            onScanComplete(data);  // Pass the scanned data up to the parent component
            setCameraActive(false); // Close camera after scan
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    return (
        <div>
            {!cameraActive ? (
                <Button variant="contained" onClick={() => setCameraActive(true)}>
                    Open Camera to Scan QR Code
                </Button>
            ) : (
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                    constraints={{
                        facingMode: 'environment', // Use rear camera on mobile
                    }}
                />
            )}
        </div>
    );
};

// AccidentReportChatBot Component
const AccidentReportChatBot = ({ initialData }) => {
    const [accidentDetails, setAccidentDetails] = useState({
        type: initialData.type || "",
        date: initialData.date || "",
        time: initialData.time || "",
        location: initialData.location || "",
        damageArea: "",
        photos: [],
        additionalInfo: "",
    });

    const [uploadedPhotos, setUploadedPhotos] = useState([]);

    const handlePhotoUpload = (files) => {
        setUploadedPhotos(files);
    };

    const handleCarPartClick = (part) => {
        setAccidentDetails((prevDetails) => ({
            ...prevDetails,
            damageArea: part,
        }));
    };

    const flow = {
        start: {
            message: "Welcome! Would you like to report a car accident?",
            checkboxes: { items: ["Yes", "No"], min: 1 },
            path: (params) => {
                const userResponse = params.userInput;
                if (userResponse === "Yes") {
                    return "selectAccidentType";
                } else {
                    return "end";
                }
            },
        },
        selectAccidentType: {
            message: "What type of accident did you have?",
            options: ["Collision with another vehicle", "Hit a stationary object", "Single-car accident"],
            path: (params) => {
                setAccidentDetails((prevDetails) => ({
                    ...prevDetails,
                    type: params.userInput,
                }));
                return "selectDamageArea";
            },
        },
        selectDamageArea: {
            message: "Please click on the part of the car that was damaged.",
            component: (
                <div>
                    <svg width="100%" height="auto" viewBox="0 0 400 300" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100,50 L150,20 L200,50 Z"
                            fill="purple"
                            opacity="0.5"
                            onClick={() => handleCarPartClick("Blind spot detection")}
                            style={{ cursor: "pointer" }}
                        />
                        <path
                            d="M300,50 L350,20 L400,50 Z"
                            fill="blue"
                            opacity="0.5"
                            onClick={() => handleCarPartClick("Rear collision warning")}
                            style={{ cursor: "pointer" }}
                        />
                        <path
                            d="M100,150 L150,120 L200,150 Z"
                            fill="orange"
                            opacity="0.5"
                            onClick={() => handleCarPartClick("Cross traffic alert")}
                            style={{ cursor: "pointer" }}
                        />
                        <path
                            d="M300,150 L350,120 L400,150 Z"
                            fill="red"
                            opacity="0.5"
                            onClick={() => handleCarPartClick("Adaptive cruise control")}
                            style={{ cursor: "pointer" }}
                        />
                    </svg>
                    {accidentDetails.damageArea && <p>Selected area: {accidentDetails.damageArea}</p>}
                </div>
            ),
            path: () => {
                if (accidentDetails.damageArea) {
                    return "askDateTime";
                }
                return "selectDamageArea";
            },
        },
        askDateTime: {
            message: "Please enter the date and time of the accident.",
            component: (
                <div>
                    <TextField
                        label="Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) =>
                            setAccidentDetails((prevDetails) => ({
                                ...prevDetails,
                                date: e.target.value,
                            }))
                        }
                    />
                    <TextField
                        label="Time"
                        type="time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) =>
                            setAccidentDetails((prevDetails) => ({
                                ...prevDetails,
                                time: e.target.value,
                            }))
                        }
                    />
                </div>
            ),
            path: () => "askLocation",
        },
        askLocation: {
            message: "Where did the accident occur?",
            inputType: "text",
            path: (params) => {
                setAccidentDetails((prevDetails) => ({
                    ...prevDetails,
                    location: params.userInput,
                }));
                return "uploadPhotos";
            },
        },
        uploadPhotos: {
            message: "You can upload photos of the accident here.",
            component: (
                <DropzoneArea
                    acceptedFiles={["image/*"]}
                    dropzoneText={"Drag and drop your photos here or click to upload"}
                    onChange={handlePhotoUpload}
                />
            ),
            path: () => {
                setAccidentDetails((prevDetails) => ({
                    ...prevDetails,
                    photos: uploadedPhotos,
                }));
                return "askAdditionalInfo";
            },
        },
        askAdditionalInfo: {
            message: "Is there any additional information you'd like to provide (injuries, witnesses, etc.)?",
            inputType: "text",
            path: (params) => {
                setAccidentDetails((prevDetails) => ({
                    ...prevDetails,
                    additionalInfo: params.userInput,
                }));
                return "confirmation";
            },
        },
        confirmation: {
            message: "Here are the details you provided. Please confirm:",
            component: (
                <div>
                    <p>Type of accident: {accidentDetails.type}</p>
                    <p>Date: {accidentDetails.date}</p>
                    <p>Time: {accidentDetails.time}</p>
                    <p>Location: {accidentDetails.location}</p>
                    <p>Damaged area: {accidentDetails.damageArea}</p>
                    <p>Uploaded photos: {uploadedPhotos.length} photo(s)</p>
                    <p>Additional information: {accidentDetails.additionalInfo}</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => alert("Report submitted!")}
                    >
                        Confirm and Submit
                    </Button>
                </div>
            ),
            options: ["Restart"],
            chatDisabled: true,
            path: () => "start",
        },
        end: {
            message: "Thank you! Feel free to reach out if you need further assistance.",
            chatDisabled: true,
        },
    };

    return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ChatBot
                settings={{
                    general: { embedded: true },
                    chatHistory: { storageKey: "accident_report_conversation" },
                }}
                flow={flow}
                onFunction={(params, step) => {
                    const stepConfig = flow[step];
                    if (stepConfig && stepConfig.function) {
                        return stepConfig.function(params);
                    }
                }}
                onPath={(step, params) => {
                    const stepConfig = flow[step];
                    if (stepConfig && stepConfig.path) {
                        return stepConfig.path(params);
                    }
                }}
            />
        </div>
    );
};

// Main Component to combine QR Scanner and ChatBot
const AccidentReportApp = () => {
    const [qrScannedData, setQrScannedData] = useState(null);

    const handleQrScanComplete = (data) => {
        const parsedData = JSON.parse(data);
        setQrScannedData(parsedData);
    };

    return (
        <div>
            {!qrScannedData ? (
                <QRCodeScanner onScanComplete={handleQrScanComplete} />
            ) : (
                <AccidentReportChatBot initialData={qrScannedData} />
            )}
        </div>
    );
};

export default AccidentReportApp;
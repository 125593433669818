import './App.css';
import MyChatBot from './components/MyChatBoxMerge';

function App() {
  return (
      <div className="App">
        <MyChatBot/>
      </div>
  );
}

export default App;